const Logo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 494.993 275.813"
    >
      <g>
        <rect
          className="logo-rect"
          x="254.993"
          y="35.813"
          width="240"
          height="240"
        />
      </g>
      <g>
        <path
          d="M33.826,20.5v58.541l66.398-69.246c3.189-3.341,5.903-5.809,8.144-7.403C110.607,0.797,113.587,0,117.309,0
			c4.858,0,8.788,1.424,11.788,4.271c2.998,2.848,4.498,6.321,4.498,10.422c0,4.936-2.544,9.833-7.63,14.691L84.85,68.335
			l47.379,67.879c3.34,4.709,5.846,8.827,7.517,12.357c1.669,3.531,2.506,6.967,2.506,10.308c0,3.721-1.463,6.947-4.385,9.681
			c-2.924,2.733-6.891,4.1-11.902,4.1c-4.708,0-8.542-1.007-11.503-3.018c-2.961-2.011-5.467-4.688-7.517-8.029
			c-2.05-3.341-3.872-6.378-5.467-9.111L61.502,90.999l-27.676,26.537v34.509c0,6.986-1.595,12.169-4.784,15.547
			c-3.189,3.377-7.289,5.067-12.3,5.067c-2.886,0-5.657-0.76-8.314-2.277c-2.659-1.519-4.708-3.568-6.15-6.15
			c-1.064-2.125-1.708-4.593-1.937-7.402C0.114,154.021,0,149.958,0,144.643V20.5C0,13.667,1.499,8.542,4.499,5.125
			C7.498,1.708,11.578,0,16.742,0c5.086,0,9.206,1.69,12.357,5.068C32.25,8.447,33.826,13.592,33.826,20.5z"
        />
        <path
          d="M269.468,20.5v87.469c0,7.594-0.323,14.048-0.968,19.362c-0.646,5.315-2.106,10.705-4.385,16.172
			c-3.798,9.188-10.062,16.344-18.792,21.469c-8.733,5.125-19.362,7.688-31.89,7.688c-11.314,0-20.748-1.784-28.302-5.353
			c-7.557-3.568-13.649-9.339-18.28-17.312c-2.431-4.251-4.404-9.148-5.922-14.692c-1.52-5.541-2.278-10.819-2.278-15.83
			c0-5.314,1.48-9.377,4.442-12.187c2.961-2.809,6.757-4.214,11.389-4.214c4.479,0,7.858,1.291,10.137,3.872
			c2.277,2.582,4.023,6.605,5.239,12.072c1.29,5.848,2.58,10.518,3.872,14.009c1.29,3.493,3.454,6.492,6.491,8.997
			c3.036,2.506,7.364,3.759,12.984,3.759c14.957,0,22.437-10.971,22.437-32.915V20.5c0-6.833,1.498-11.958,4.499-15.375
			C243.14,1.708,247.221,0,252.385,0c5.239,0,9.396,1.708,12.471,5.125S269.468,13.667,269.468,20.5z"
        />
        <path
          d="M340.195,20.5v47.835h73.004V20.5c0-6.833,1.538-11.958,4.613-15.375S424.93,0,429.941,0c5.086,0,9.206,1.69,12.357,5.068
			c3.15,3.379,4.727,8.523,4.727,15.432v131.545c0,6.91-1.595,12.073-4.783,15.489c-3.189,3.417-7.289,5.125-12.301,5.125
			c-5.087,0-9.15-1.728-12.186-5.182c-3.038-3.454-4.557-8.599-4.557-15.433V95.896h-73.004v56.148
			c0,6.91-1.595,12.073-4.783,15.489c-3.189,3.417-7.289,5.125-12.301,5.125c-5.088,0-9.15-1.728-12.187-5.182
			c-3.037-3.454-4.556-8.599-4.556-15.433V20.5c0-6.833,1.499-11.958,4.499-15.375C313.866,1.708,317.947,0,323.111,0
			c5.086,0,9.206,1.69,12.357,5.068C338.618,8.447,340.195,13.592,340.195,20.5z"
        />
      </g>
    </svg>
  );
};

export default Logo;
